<template>
  <main v-if="ListTodo.status != 'false'" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue"><i class="fas fa-project-diagram margin-r" aria-hidden="true"></i>Edit ToDo </h3>
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important">
                <h6 class="text-start card-title fw-bold"> ToDo Info </h6>
                <div class="container mt-4">
                  <form ref="ProjectForm" @submit.prevent="PostEditTodo()">
                    <div class="col-md-12" align="left">
                      <label for="validationDefault01" class="form-label">Name</label>
                      <input type="text" v-model="TodoPost.todo" class="form-control" id="validationDefault01" required placeholder="Name of Project">
                    </div>
                    <div class="col-md-12" align="left">
                      <label for="project" class="form-label">Description</label>
                      <textarea v-model="TodoPost.description" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                    <div class="d-flex justify-content-end mt-3 m-1">
                       <a class=" btn btn-danger"  style="margin-right: 5px;" @click="$router.go(-1)"> Go Back</a>
                      <button :disabled="Form.submit"  class="button-1 btn btn-primary" type="submit">Save ToDo</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request'; 

  export default
  {
    name: 'ContentEditTodo',
    data: () => (
    {
      TodoPost: 
      {
        id:"",
        todo: "",
        description:"",
        status:"",
        message:""
      },
      ListTodo: 
      {
        id:"",
        status:""
      },
      Form: 
      {
        submit: true
      }
    }),
    methods: 
    {
      PostEditTodo() 
      {
        Api.post("/todo/edit",this.TodoPost).then((result) => 
        {
          this.Form.submit = true

          if(result.data.status == "true")
          {
            this.TodoPost.status = result.data.status
            this.TodoPost.message = result.data.msg;

            this.$moshaToast( ''+this.$filters.OnlyLetters(this.TodoPost.message)+'', { type: 'default', position:'bottom-right', transition:'zoom' })
        
            setTimeout( () => 
            {
              this.TodoPost.message = ""
              this.TodoPost.status = ""
              this.Form.submit = false
            }, 5000);
          }
        })    
      },
      GetTodo() 
      {
        Api.get('/todo/list/' + this.$route.params.id).then((result) => 
        {
          this.ListTodo.status = result.data.status

          if(this.ListTodo.status == "true")
          {
            this.TodoPost.todo =  result.data.todo[0].todo
            this.TodoPost.id =  result.data.todo[0].id
            this.TodoPost.description =  result.data.todo[0].description
            this.Form.submit = false
          }
        })
      }
    },
    mounted()
    {
      this.GetTodo()
    }
  }
</script>

<style></style>
