<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentEditTodo /> 
</template>
<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import ContentEditTodo from '@/components/team/dashboard/modules/to-do/ContentEditTodo.vue'

export default 
{
  name: 'EditTodo',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentEditTodo
  }
}
</script>
<style>

</style>
